import { render, staticRenderFns } from "./enroll.vue?vue&type=template&id=4a6f724f&"
import script from "./enroll.vue?vue&type=script&lang=js&"
export * from "./enroll.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/hamzakambaza/Desktop/Sites/kebbi-cct/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4a6f724f')) {
      api.createRecord('4a6f724f', component.options)
    } else {
      api.reload('4a6f724f', component.options)
    }
    module.hot.accept("./enroll.vue?vue&type=template&id=4a6f724f&", function () {
      api.rerender('4a6f724f', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/views/beneficiary/enroll.vue"
export default component.exports