<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-7 col-12 mb-2">
                <div class="row breadcrumbs-top">
                    <div class="col-12">
                        <h2 class="content-header-title float-left mb-0">{{trans('beneficiary.enrolled_beneficiaries')}}
                        </h2>
                        <div class="breadcrumb-wrapper col-12">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item">
                                    <router-link to="/home">
                                        {{ trans('general.home') }}
                                    </router-link>
                                </li>
                                <li class="breadcrumb-item active">
                                    {{ trans('beneficiary.enrolled_beneficiaries') }}
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content-header-right text-md-right col-md-5 col-12 d-md-block d-none">
                <div class="form-group breadcrum-right">
                    <button class="btn btn-primary btn-sm pull-right ml-1"  @click="$router.push('beneficiaries/create')"><i class="feather icon-plus"></i> {{trans('beneficiary.create_beneficiary')}}</button>
                    
                    <button class="btn btn-primary btn-sm pull-right" v-if="!showFilterPanel" @click="showFilterPanel = !showFilterPanel"><i class="feather icon-filter"></i> {{trans('general.filter')}}</button>
                    
                </div>
            </div>

        </div>

    <div class="content-body">
        <section id="basic-datatable">
            <div class="row">
                <div class="col-12">

                    <transition name="fade">
                        <div class="card border-bottom" v-if="showFilterPanel">
                            <div class="card-header">
                                <h4 class="card-title">
                                    {{trans('general.filter')}}
                                </h4>
                            </div>
                            <div class="card-content">
                                <div class="card-body">
                                <div class="row">
                                    <div class="col-3">
                                        <div class="form-group">
                                            <label for="">{{trans('beneficiary.cohort')}}</label>
                                            <v-select :options="cohorts" :reduce="name => name.id" label="name" name="cohort_id" v-model="filterBeneficiaryForm.cohort_id"></v-select>
                                            
                                        </div>
                                    </div>

                                    <div class="col-3">
                                        <div class="form-group">
                                            <label for="">{{trans('beneficiary.class')}}</label>
                                            <v-select :options="classes" :reduce="name => name.id" label="name" name="class_id" v-model="filterBeneficiaryForm.class_id"></v-select>
                                            
                                        </div>
                                    </div>

                                    <div class="col-3">
                                        <div class="form-group">
                                            <label for="">{{trans('beneficiary.lga')}}</label>
                                            <v-select @input="getSchools" :options="lgas" :reduce="name => name.id" label="name" name="lga_id" v-model="filterBeneficiaryForm.lga_id"></v-select>
                                            
                                        </div>
                                    </div>
                                                                
                                    <div class="col-3">
                                        <div class="form-group">
                                            <label for="">{{trans('school.school')}}</label>
                                            <v-select :options="schools" :reduce="name => name.id" label="name" name="school_id" v-model="filterBeneficiaryForm.school_id"></v-select>
                                            
                                        </div>
                                    </div>
                                </div>
                                <button class="btn btn-danger waves-effect waves-light pull-right mr-1 mb-1" v-if="showFilterPanel" @click="showFilterPanel = !showFilterPanel">{{trans('general.cancel')}}</button>
                            </div>
                            </div>
                        </div>
                    </transition>
                    
                    <div class="card">
                        <div class="card-header">
                            <h4 v-if="beneficiaries.total" class="card-title">
                                <sort-by :order-by-options="orderByOptions" :sort-by="filterBeneficiaryForm.sort_by" :order="filterBeneficiaryForm.order" @updateSortBy="value => {filterBeneficiaryForm.sort_by = value}"  @updateOrder="value => {filterBeneficiaryForm.order = value}"></sort-by>
                            </h4>
                        </div>
                        <div class="card-content">
                            <div class="card-body">
                                <div class="table-responsive" v-if="beneficiaries.total">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th>{{trans('general.serial_number')}}</th>
                                            <th>Photo</th>
                                            <th>{{trans('beneficiary.unique_id')}}</th>
                                            <th>{{trans('beneficiary.school')}}</th>
                                            <th>{{trans('beneficiary.class')}}</th>
                                            <th>{{trans('beneficiary.name')}}</th>
                                            <th>{{trans('general.status')}}</th>
                                            <!-- <th class="table-option">{{trans('general.action')}}</th> -->
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(beneficiary, index) in beneficiaries.data">
                                            <td v-text="index+1"></td>
                                            <td><img :src="`/uploads/beneficiaries/` + beneficiary.photo + `.jpeg`" alt="" class="" height="50" width="50" /></td>
                                            <td v-text="beneficiary.unique_id"></td>
                                            <td v-text="beneficiary.school.name"></td>
                                            <td v-text="beneficiary.class"></td>
                                            <td v-text="beneficiary.name"></td>
                                            <td v-html="getStatus(beneficiary)"></td>
                                            <!-- <td class="table-option">
                                                <div class="btn-group">
                                                    
                                                    <router-link :to="`beneficiaries/${beneficiary.id}`" class="btn btn-success btn-sm" v-tooltip="trans('beneficiary.view_beneficiary')"><i class="feather icon-eye"></i></router-link>
                                                    <button class="btn btn-info btn-sm" v-tooltip="trans('beneficiary.edit_beneficiary')" @click.prevent="editBeneficiary(beneficiary)" v-if="hasPermission('edit-beneficiaries')"><i class="feather icon-edit"></i></button>
                                                    <button class="btn btn-danger btn-sm" :key="beneficiary.id" v-if="hasPermission('delete-beneficiaries')" v-confirm="{ok: confirmDelete(beneficiary)}" v-tooltip="trans('beneficiary.delete_beneficiary')"><i class="feather icon-trash-2"></i></button>
                                                </div>
                                            </td> -->
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <module-info v-if="!beneficiaries.total" module="beneficiary" title="module_info_title" description="module_info_description" icon="check-circle">
                                <div slot="btn">
                                    <button class="btn btn-primary btn-md" @click="$router.push('beneficiaries/create')"><i class="feather icon-plus"></i> {{trans('general.add_new')}}</button>
                                </div>
                            </module-info>
                            <pagination-record :page-length.sync="filterBeneficiaryForm.page_length" :records="beneficiaries" @updateRecords="getBeneficiaries"></pagination-record>
                             
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
   
   </div>
    </div>
</template>

<script>
    import sortBy from '../../components/sort-by'
    import dateRangePicker from '../../components/date-range-picker'

    export default {
        components : { sortBy,dateRangePicker },
        data() {
            return {
                userRole: '',
                beneficiaries: {
                    total: 0,
                    data: []
                },
                filterBeneficiaryForm: {
                    keyword: '',
                    start_date: '',
                    end_date: '',
                    sort_by : 'created_at',
                    order: 'desc',
                    page_length: helper.getConfig('page_length'),
                    cohort_id: '',
                    school_id: '',
                    lga_id: '',
                    class_id: ''
                },
                orderByOptions: [
                    {
                        value: 'admission_number',
                        translation: i18n.beneficiary.admission_number
                    },
                    {
                        value: 'name',
                        translation: i18n.beneficiary.name
                    },
                    {
                        value: 'email',
                        translation: i18n.beneficiary.email
                    }
                ],
                showFilterPanel: false,
                cohorts: [],
                lgas: [],
                schools: [],
                classes: [],
            };
        },
        mounted(){
            if(!helper.hasPermission('list-beneficiaries')){
                helper.notAccessibleMsg();
                this.$router.push('/home');
            }

            if(!helper.featureAvailable('beneficiary')){
                helper.featureNotAvailableMsg();
                this.$router.push('/home');
            }

            this.fetchPreRequisites();
            this.getBeneficiaries();
        },
        methods: {
            fetchPreRequisites(){
                axios.get('/api/user/pre-requisite')
                    .then(response => response.data)
                    .then(response => {
                        this.cohorts = response.cohorts;
                        this.lgas = response.lgas;
                        this.classes = response.classes_for_filter;
                    })
                    .catch(error => {
                        helper.showDataErrorMsg(error)
                    });
            },
            hasPermission(permission){
                return helper.hasPermission(permission);
            },
            getBeneficiaries(page){
                if (typeof page !== 'number') {
                    page = 1;
                }
                let url = helper.getFilterURL(this.filterBeneficiaryForm);
                axios.get('/api/beneficiaries?page=' + page + url)
                    .then(response => response.data)
                    .then(response => this.beneficiaries = response)
                    .catch(error => {
                        helper.showDataErrorMsg(error);
                    });
            },
            getSchools(event){
                axios.get('/api/schools-by-lga-id/' + event)
                    .then(response => response.data)
                    .then(response => {
                        this.schools = response.schools;
                    })
                    .catch(error => {
                        helper.showDataErrorMsg(error)
                    });
            },
            editBeneficiary(beneficiary){
                this.$router.push('beneficiaries/'+beneficiary.id+'/edit');
            },
            confirmDelete(beneficiary){
                return dialog => this.deleteBeneficiary(beneficiary);
            },
            deleteBeneficiary(beneficiary){
                axios.delete('/apibeneficiaries/'+beneficiary.id)
                    .then(response => response.data)
                    .then(response => {
                        toastr.success(response.message);
                        this.getBeneficiaries();
                    }).catch(error => {
                        helper.showDataErrorMsg(error);
                    });
            },
            getStatus(beneficiary){
                return beneficiary.is_enrollment ? ('<span class="badge badge-success">'+i18n.beneficiary.enrolled+'</span>') : ('<span class="badge badge-warning">'+i18n.beneficiary.not_enrolled+'</span>') ;
            }
        },
        watch: {
            filterBeneficiaryForm: {
                handler(val){
                    setTimeout(() => {
                        this.getBeneficiaries();
                    }, 500)
                },
                deep: true
            }
        }
    }
</script>
