import { render, staticRenderFns } from "./index.vue?vue&type=template&id=f55fd54a&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/hamzakambaza/Desktop/Sites/kebbi-cct/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('f55fd54a')) {
      api.createRecord('f55fd54a', component.options)
    } else {
      api.reload('f55fd54a', component.options)
    }
    module.hot.accept("./index.vue?vue&type=template&id=f55fd54a&", function () {
      api.rerender('f55fd54a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/views/message/index.vue"
export default component.exports